<template>
  <v-dialog
    v-model="dialog"
    max-width="720"
    persistent
  >
    <v-card>
      <v-card-title>{{ $t('views.orders.start_new_order') }}</v-card-title>
      <v-card-text>
        <order-new-settings
          ref="settings"
          with-cancel
          @onConfirm="agree"
          @onCancel="cancel"
        ></order-new-settings>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import orderNewSettings from './orderNewSettings';

  export default {
    name: "confirmSettings",
    components: {
      orderNewSettings,
    },
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          color: "grey lighten-3",
          width: 400,
          zIndex: 200,
          noconfirm: false,
        },
      };
    },
    methods: {
      open() {
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        if(this.$refs.settings.validate()) {
          this.resolve(true);
          this.dialog = false;
        }
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>